import React from "react";
import Layout from "@common/Layout/Layout";
import Vacancy from "@components/Vacancy/Vacancy";
import SEO from "../components/Common/SEO/SEO";

const VacancyTemplate = ({ path, pageContext }) => {
    const { vacancy } = pageContext;
    return (
        <>
            <SEO
                title={vacancy.name}
                titleTemplate="Impressit"
                description={`We are hiring ${vacancy.name}`}
                path={path}
                ownMeta={{ name: "googlebot", content: "noindex, nofollow" }}
            />
            <Layout path={path}>
                <Vacancy vacancy={vacancy} />
            </Layout>
        </>
    );
};

export default VacancyTemplate;
