import React from "react";
import catchUpImage from "@images/home-page/catchUpImage.svg";
import catchUpImageHuge from "@images/home-page/catchUpImageHuge.svg";
import {
  MAIN_BUTTON,
  SECONDARY_BUTTON,
  HEADER_SECONDARY_BUTTON,
  HEADER_MAIN_BUTTON,
} from "@constants";
import Button from "@common/Button/Button";
import CircleBlur from "@common/CircleBlur/CircleBlur";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "../../../../links";
import {
  ButtonWrapper,
  Description,
  SecondaryTitle,
} from "../../../../styles/CommonStyles";
import {
  CatchUpSectionWrapper,
  CatchUpSectionContainer,
  HoverContainer,
  TextContainer,
  BorderImage,
} from "./SecondCatchUpSection.style";

const SecondCatchUpSection = ({
  isAnimated,
  title,
  description,
  buttonText,
  secondButtonText,
  handleClick,
}) => {
  return (
    <CatchUpSectionWrapper className="catchup-section__second">
      <CatchUpSectionContainer
        isAnimated={isAnimated}
        isBackground={!secondButtonText}
      >
        <HoverContainer isAnimated={isAnimated}>
          {isAnimated && (
            <CircleBlur
              width="54.375rem"
              height="18.75rem"
              blur="blur(100px)"
            />
          )}
          <BorderImage
            src={secondButtonText ? catchUpImageHuge : catchUpImage}
            alt="background image"
            className="catchup-section__image"
            isBigImage={secondButtonText}
          />
          <TextContainer isDescription={!!description}>
            <SecondaryTitle>{title}</SecondaryTitle>
            {description && <Description>{description}</Description>}
          </TextContainer>
          <ButtonWrapper
            width="11.875rem"
            isSecondButton={secondButtonText}
            isCentered
            isWhite
          >
            <Button
              text={buttonText}
              type={MAIN_BUTTON}
              size={secondButtonText ? HEADER_MAIN_BUTTON : undefined}
              {...(!!handleClick
                ? { handleClick }
                : { href: GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US })}
            />
            {secondButtonText && (
              <Button
                text={secondButtonText}
                type={SECONDARY_BUTTON}
                size={HEADER_SECONDARY_BUTTON}
                href={GLOBAL_APPLICATION_INTERNAL_LINKS.CAREERS}
              />
            )}
          </ButtonWrapper>
        </HoverContainer>
      </CatchUpSectionContainer>
    </CatchUpSectionWrapper>
  );
};

export default SecondCatchUpSection;
